import { createMuiTheme } from '@material-ui/core/styles'

const palette = {
  primary: {
    light: '#09a267',
    main: '#f71d6e',
    contrastText: '#ffffff',
    text: '#797979'
  },
  secondary: {
    light: '#ec5357',
    main: '#ae0061',
    contrastText: '#ffffff'
  },
  tertiary: {
    main: '#19134F'
  },
  contrast: {
    main: '#b30b44'
  },
  text: {
    main: '#5d5d5d'
  },
  accent: {
    main: '#025233'
  },
  success: {
    main: '#4caf50'
  },
  info: {
    main: '#2196f3'
  },
  warning: {
    main: '#ff9800'
  },
  error: {
    main: '#f44336'
  }
}

const theme = createMuiTheme({
  palette,
  typography: {
    fontFamily: [
      'Exo',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  defaultButton: {
    borderRadius: 50,
    padding: '11px 30px',
    boxShadow: 'none'
  }
})

export default theme
import React from 'react'
import { ThemeProvider } from '@material-ui/core';
import theme from './src/components/theme';
import './src/styles/fonts.css'

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider
      theme={theme}>
      {element}
    </ThemeProvider>
  );
}